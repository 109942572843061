<template>
  <div>
    <div class="main">
      <div class="main__header">
        <div class="main__logo">
          <img
            src="@/assets/images/homeImg/logoHeader.png"
            alt
            class="main__logo-img"
          />
        </div>
      </div>
      <div class="main__form">
        <div class="main__form-title">重置密码</div>
        <a-form-model
          ref="form"
          :model="form"
          @submit="handleSubmit"
          :rules="rules"
        >
          <a-form-model-item prop="mobile">
            <a-input
              size="large"
              v-model="form.mobile"
              placeholder="请输入手机号"
              :maxLength="11"
            >
              <a-icon
                slot="prefix"
                type="mobile"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="code">
            <a-row>
              <a-col :span="16">
                <a-input
                  size="large"
                  v-model="form.code"
                  placeholder="请输入验证码"
                  :maxLength="6"
                >
                  <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                  />
                </a-input>
              </a-col>
              <a-col :span="8">
                <a-button
                  block
                  size="large"
                  @click="getCode"
                  style="color: #376cde; font-size: 14px"
                  >{{ btnText }}</a-button
                >
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input
              size="large"
              v-model="form.password"
              placeholder="请输入新密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item class="margin_bottom_none">
            <a-button
              block
              size="large"
              shape="round"
              type="primary"
              :loading="loading"
              html-type="submit"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div class="main__form-forget" @click="toAccount">返回登录</div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>

<script>
import footerNav from "@/components/footer-nav";
// import loading from "@/components/loading";
import { pcGetCode, resetPasswrod } from "@/api/account";

let validateMonile = (rule, value, callback) => {
  //11位手机号码正则表达式
  let reg =
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  if (value === "") {
    callback(new Error("手机号不能为空!"));
  } else if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号!"));
  } else {
    callback();
  }
};

// 验证码的验证
let validateCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("验证码不能为空!"));
  } else {
    callback();
  }
};

// 密码的验证
let validatePassword = (rule, value, callback) => {
  let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
  if (value === "") {
    callback(new Error("新密码不能为空!"));
  } else if (!reg.test(value) && value.length < 8) {
    callback(new Error("密码至少由8位数字、大小写字母和特殊符号组成。"));
  } else {
    callback();
  }
};

const rules = {
  mobile: [
    {
      validator: validateMonile,
    },
  ],
  code: [
    {
      validator: validateCode,
    },
  ],
  password: [
    {
      validator: validatePassword,
    },
  ],
};

export default {
  components: { footerNav },
  data() {
    return {
      isCode: true,
      loading: false,
      form: {
        mobile: "",
        code: "", // 通用验证码,调试用
        password: "",
      },
      rules,
      btnText: "获取验证码",
      timer: null,
      codeLoading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 获取验证码
    getCode() {
      if (this.btnText > 0) return false;
      this.$refs.form.validateField("mobile", (value) => {
        if (value === "") {
          this.btnText = 60;
          this.timer = setInterval(() => {
            if (this.btnText <= 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.btnText = "重新获取";
              return;
            }
            this.btnText--;
          }, 1000);
          pcGetCode({
            smsType: 2,
            telephone: this.form.mobile,
          }).catch((err) => {
            this.$message.error(err);
          });
        } else {
          this.$message.warning("手机号不能为空");
        }
      });
    },

    // 重置密码
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          resetPasswrod({
            captcha: this.form.code,
            telephone: this.form.mobile,
            password: this.form.password,
          })
            .then((res) => {
                console.log(res)
              this.$message.loading({
                content: "密码重置中...",
                key: "updatable",
              });
              setTimeout(() => {
                this.$message.success({
                  content: "密码重置!",
                  key: "updatable",
                  duration: 2,
                });
                this.$router.push({
                  name: "login",
                });
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    // 返回登陆
    toAccount() {
        this.$router.back()
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 827px;
  background: url("~@/assets/images/login/login-bg.png") no-repeat;
  position: relative;
  background-size: auto 100%;
  &__header {
    border-bottom: 2px solid #FFFFFF;
  }
  &__logo {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &-img {
      margin-right: 6px;
      width: 197px;
    }
    &-text {
      font-size: 28px;
      font-weight: bold;
      color: #c4a468;
    }
    &-title {
      font-size: 28px;
      color: #c4a468;
      font-weight: 600;
    }
  }
  &__form {
    width: 360px;
    height: 446px;
    position: absolute;
    top: 240px;
    right: 387px;
    background: #fff;
    border-radius: 6px;
    &-title {
      font-size: 30px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #5DB4FF;
      line-height: 36px;
      text-align: center;
      margin: 37px auto 31px;
      &_mb {
        margin: 37px auto 16px;
      }
    }
    &-forget {
      color: #5DB4FF;
      display: inline-block;
      float: right;
      margin-right: 30px;
      margin-bottom: 30px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .margin_bottom_none{
    margin-bottom: 0;
  }
}
::v-deep.ant-form {
  width: 300px;
  margin: 0 auto;
}

::v-deep.ant-form-item {
  margin-bottom: 20px;
}

::v-deep .ant-btn-primary{
  background-color: #5DB4FF;
  border-color: #5DB4FF;
}
</style>